<template>
  <div class="page">
    <!--pages/mytickets/mytickets.wxml-->
    <!--<span>我的门票</span>-->
    <div class="head flexcenter">
      <div :class="['item', {active: tab === 0}]" @click="toggleTab(0)">
        全部
      </div>
      <div :class="['item', {active: tab === 1}]" @click="toggleTab(1)">
        正则出票
      </div>
      <div :class="['item', {active: tab === 2}]" @click="toggleTab(2)">
        已出票
      </div>
      <div :class="['item', {active: tab === 3}]" @click="toggleTab(3)">
        已完成
      </div>
      <div :class="['item', {active: tab === 4}]" @click="toggleTab(4)">
        已取消
      </div>
    </div>
  
  
    <div class="list">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
      >
	  <div class="item" v-for="(order, idx) in orderTickets">
        <div class="status-box flexcenter">
          <div class="left">{{order.supplier_name}}</div>
          <div class="right">{{order.pay_status_text}},{{order.status_text}}</div>
        </div>
        <div class="detail-box">
          <div class="guest-list" v-for="(item, idx) in order.goods">
            <div class="left">
              <img class="full-img" mode="widthFix" :src="item.default_image">
            </div>
            <div class="right">
              <div class="title-box">
                <div class="title ellipsis2">{{item.abbreviation}}</div>
                <div class="price">￥{{item.sale_price}}</div>
              </div>
              <div class="desc-box flexcenter">
                <div>持票人：{{item.user_name}}</div>
                <div>x1</div>
              </div>
            </div>
          </div>
  
          <div class="price-box">
            <span class="price">订单金额 ￥{{order.order_amount}}</span>
          </div>
          
        </div>
      
		<div class="action-box flexcenter" v-if="order.pay_status=='0'&&order.status=='0'">
		  <div></div>
		  <div class="gopay" @click.stop="goSelPayment(order.order_id)">继续支付</div>
		</div>
		<div class="action-box flexcenter" v-else-if="order.status=='-1'">
		  <div></div>
		</div>
		<div class="action-box flexcenter" v-else>
		  <div></div>
		  <div class="cancel" @click.stop="ticketDetail(order.order_id)" >查看订单</div>
		</div>
      </div>
      </van-list>
	  
    </div>
	
	<van-popup round v-model="showSelPayment" bind:close="closeSelPayment">
	  <div class="selPaymentListBox">
	    <div class="paymentItem" @click="onSelPayment(idx)" v-for="(item, idx) in paymentList">
	      <div v-if="currentPayment.id==item.id" class="checkbox iconfont iconxuanzhong"></div>
	      <div v-else class="checkbox iconfont iconweixuanzhong"></div>
	      <div class="payment">
	        <div class="icon"><img :src="item.icon"></img></div>
	        <div class="name">{{item.name}}</div>
	      </div>
	    </div>
	  </div>
	</van-popup>
  </div>
</template>

<script>
  import Vue from 'vue';
  import { List } from 'vant';
		
  Vue.use(List);
  export default {
    name: 'mytickets',
    data() {
      return {
        tab: 0,
        filter:{}, //搜索条件
        page:'',
        totalPage:'',
        orderTickets:[],
        showSelPayment:false, //显示支付方式选择弹框
        goPayOid:'',
        paymentList:[], //可用支付方式
        currentPayment:[],
		loading: false,
		finished: false,
      }
    },
    created() {
      this.getOrderList(1,true)
    },
    methods: {
      toggleTab(idx) {
        let filter = this.filter;

        if(idx=='0') { filter.o_status=1; filter.pay_status = 1; }
        if(idx=='1') { filter.o_status=0; filter.pay_status = 0; }
        if(idx=='2') { filter.o_status=[3,4]; filter.pay_status = 1; }
        if(idx=='3') { filter.o_status=['-1','-2'];}
        if(idx=='4') { filter.o_status=''; filter.pay_status = ''; }

        this.tab = idx
        this.filter = filter
        this.getOrderList(1,true);
      },
      //获取商品信息数据
      getOrderList(pageNo, override){
        let that = this;
        this.post(
          this.API.tourOrderList,
          {page:pageNo, filter:that.filter}
        ).then(response => {
          let orderList = response.data.orders.data;
          this.paymentList =response.data.payments
          this.page = response.data.orders.current_page     //当前的页号
          this.totalPage = response.data.orders.last_page  //总页数
          this.orderTickets = override ? orderList : that.orderTickets.concat(orderList)
        })
      },
      ticketDetail(oid){
        this.$router.push({
          name: 'mytickets-detail',
          query: {
            oid
          }
        })
      },
      /**选择支付方式 */
      goSelPayment:function(oid){
        this.showSelPayment = true
        this.goPayOid = oid
      },
      closeSelPayment:function(){
        this.showSelPayment=false
      },
      onSelPayment:function(idx){
        this.currentPayment = this.paymentList[idx]
        this.showSelPayment = false

        this.goPay()
      },
      /**
       * 继续支付
       */
      goPay:function(e){
        let oid = this.goPayOid;
        if(oid==''||typeof(oid)=="undefined")
        {
          this.$toast("缺少订单参数");
          return;
        }
        let that = this;
		
		this.post(this.API.goPayTicketOrder,{oid:oid, payment:that.currentPayment.id})
			.then(response => {
			  if(response.data.err=='1'){
				this.$toast(response.data.msg)
				return false;
			  }
			  else
			  {
				if(response.data.paydata.data.type=='local')
				{
				  this.$toast('订单支付成功!');
				  that.getOrderList(1,true);
				  return ;
				}
				
				let trans_no = response.data.paydata.data.trans_no;
				// TODO: 需要请求后端接口获取订单号
				upsdk.pay({
				  tn: response.data.paydata.data.result.tn,
				  success: function(res){
					console.log(res);
				    that.checkPay(trans_no)
				  },
				  fail: function(err){
				    // 支付失败, err.msg 是失败原因描述, 比如TN号不合法, 或者用户取消了交易 等等。
					console.log(err);
					that.cancelPay(err)
				  }
				});
			  }
			  
			})
        
      },
      checkPay:function(trans_no) {
        let that = this;
        this.post(this.API.checkPay,{trans_no:trans_no})
        	.then(response => {
        	  if(response.data.err=='0') {
        		that.$toast('支付成功');
        	  }else{
        		that.$toast(response.data.msg)
        	  }
        	  that.getOrderList(1,true);
        })
      },
	  cancelPay:function(res) {
	  		  console.log(res);
	  		  this.$toast("您取消了支付"+res.msg);
	  },
	  onLoad(){
	  		 console.log("触底下拉");
	  		 console.log(this.page);
	  		 console.log(this.totalPage);
	  		if (this.page < this.totalPage) {
	  		    this.getOrderList(parseInt(this.page) + 1,false)
	  		}else if(this.page>=this.totalPage){
	  			if(this.totalPage>0){
	  				this.$toast("这就是全部")
	  			}
	  			this.loading=false;
	  		}
	  }
	  
    }
  };
</script>

<style lang="scss" scoped>
  .page {
      background-color: #F5F5F5;
      min-height: 100%;
      font-size: 32px;
      line-height: 1.6;
      font-family: "PingFang SC";
  }
  
  .head {
      justify-content: space-between;
      height: 88px;
      background-color: #FFFFFF;
      font-size: 24px;
      color: #777777;
  }
  .head .item {
      width: 137px;
      height: 88px;
      line-height: 88px;
      text-align: center;
  }
  .head .active {
      position: relative;
      color: #191919;
      font-weight: bold;
  }
  .head .active:after {
      content: "";
      position: absolute;
      left: 50%;
      bottom: 0;
      margin-left: -48px;
      width: 96px;
      height: 8px;
      border-radius: 50px 50px 0 0;
      background-color: #F8D414;
  }
  
  .list {
      padding: 24px;
  }
  .item {
      margin-bottom: 24px;
      padding: 0 24px;
      border-radius: 8px;
      background-color: #FFFFFF;
  }
  
  .item .status-box {
      justify-content: space-between;
      height: 85px;
  }
  .item .status-box .left {
      font-size: 24px;
      color: #999999;
  }
  .item .status-box .right {
      font-size: 28px;
      color: #F8D414;
  }
  
  .item .detail-box {
      justify-content: space-between;
      padding-bottom: 24px;
  }
  .item .detail-box .guest-list {
      width: 100%;
      display: flex;
      border-bottom: 1px solid #eeeeee;
      margin-bottom: 10px;
      padding-bottom: 10px;
  }
  .item .detail-box .left {
      margin-right: 20px;
      width: 140px;
      border:1px solid #eeeeee;
  }
  .item .detail-box .left image {
  
  }
  .item .detail-box .right {
      flex: 1;
  }
  .item .detail-box .right .title-box {
      display: flex;
      justify-content: space-between;
      font-size: 28px;
      color: #191919;
  }
  .item .detail-box .right .title-box .title {
      width: 346px;
  }
  .item .detail-box .right .desc-box {
      justify-content: space-between;
      font-size: 24px;
      margin: 24px 0;
      color: #777777;
  }
  .item .detail-box .price-box {
      text-align: left;
      font-size: 24px;
      color: #777777;
      line-height: 68px;
  }
  .item .detail-box .price-box .price{
      font-size: 28px;
      color: #191919;
  }
  
  .item .action-box {
      justify-content: space-between;
      height: 104px;
      margin-top: -108px;
  }
  .item .action-box .cancel {
      width: 160px;
      height: 56px;
      line-height: 56px;
      text-align: center;
      border: 2px solid #999999;
      border-radius: 28px;
      font-size: 28px;
      color: #777777;
  }
  
  .item .action-box .gopay {
      width: 160px;
      height: 56px;
      line-height: 56px;
      text-align: center;
      border: 2px solid #FCC601;
      background:#FCC601;
      border-radius: 28px;
      font-size: 28px;
      color: #FFFFFF;
      display: inline-block;
      margin-left: 20px;
  }
</style>
